import React from 'react';
import { Typography } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

export function SingleProductComponent({ product,navigateToDetailedProductView }: any) {
  return (
    <div
      style={{
        minWidth:"300px",
        width: "300px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        cursor:"pointer",
        backgroundColor: "rgba(237, 230, 220, 1)",
      }}
      onClick={()=>navigateToDetailedProductView(product?.catalogue_id||product?.attributes?.catalogue_id)}
    >
      <div
        className="imgDiv"
        style={{
          height: "350px",
          width: "100%",
          borderRadius: "4px",
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              cursor: "pointer",
              padding: "2px",
              float: "right",
              marginTop: "-50px",
              marginRight: "15px",
              borderRadius: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "28px",
              width: "28px",
              // border: "1px solid rgba(83, 80, 63, 1)", 
            }}
          >
            {/* <FavoriteIcon
              sx={{
                color: "#EDE6DC",
                border:"1px solid rgba(83, 80, 63, 1)",
                "& path": {
                  fill: "white",
                },
              }}
            /> */}
            <FavoriteBorderIcon sx={{color:"rgba(83, 80, 63, 1)",fontSize: "26px",}}/>
          </div>
        </div>
        <img
          height={210}
          width={210}
          src={product?.image||product?.attributes?.image_url
          }
          alt=""
        />
      </div>
      <Typography
        style={{
          fontFamily: 'Montserrat, sans-serif',
          fontSize: "22px",
          fontWeight: 600,
          lineHeight: "17.55px",
          textAlign: "left",
          color: "#53503F",
          marginLeft: "6px",
          marginBottom: "6px",
          marginTop: "20px",
        }}
      >
        {product?.catalogue_name||product?.attributes?.name}
      </Typography>
      <span
        style={{
          fontFamily: 'Montserrat, sans-serif',
          fontSize: "16px",
          fontWeight: 400,
          textAlign: "left",
          marginLeft: "6px",
          marginBottom: "5px",
          display: "inline-block",
          maxWidth: "100%",
          wordWrap: "break-word",
          whiteSpace: "normal",
          color: "#53503F",
          marginTop: "5px"
        }}
      >
        {(product?.catalogue_description||product?.attributes?.description)?.replace(/<\/?[^>]+(>|$)/g, "")}
      </span>
      <Typography
        style={{
          fontFamily: 'Montserrat, sans-serif',
          fontSize: "20px",
          fontWeight: 500,
          textAlign: "left",
          marginLeft: "6px",
          marginBottom: "13px",
          color: "#53503F",
        }}
      >
        {product?.price||product?.attributes?.price}
      </Typography>
    </div>
  );
}
