import React from "react";
import { styled } from "@mui/system";
import {
  Modal,
  Box,
  TextField,
  IconButton,
  DialogActions,
  Backdrop,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FooterButton = styled("button")({
  width: "360px",
  height: "56px",
  padding: "16px 0px",
  borderRadius: "8px",
  color: "white",
  border: "none",
  backgroundColor: "#53503F",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "18px",
  fontWeight: 800,
  cursor: "pointer",
});
const ScrollableContent = styled(Box)({
  width: "100%",
  boxSizing: "border-box",
  overflowY: "auto",
  borderRadius: "8px",
  paddingBottom: "65px",
  flexDirection: "column",
  // backgroundColor: '#EFEEE9',
  "&::-webkit-scrollbar": {
    width: "7px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f9f9f9",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#53503F",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#53503F",
  },
});
const MyTextField = styled(TextField)(({ theme }) => ({
  width: "360px",
  boxSizing: "border-box",
  height: "56px",
  justifyContent: "center",
  padding: "10px 8px",
  gap: 8,
  color: "rgba(83, 80, 63, 1)",
  borderRadius: "4px",
  borderWidth: "0.2px 0 0 0",
  backgroundColor: "rgba(255, 255, 255, 1)",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      width: "100%",
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "300px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const MakeOffer = ({openModal,handleMakeOfferModal,productData, handleChange,offerValue,offerErr,handleSubmit}:any) => {
  return (
    <>
      <Modal
        open={openModal}
        onClose={handleMakeOfferModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          maxWidth: "100%",
          backdropFilter: "blur(8px)",
        }}
      >
        <Box sx={webStyle.container}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "24px",
            }}
          >
            <CloseIcon
              onClick={handleMakeOfferModal}
              style={{
                height: "24px",
                width: "24px",
                float: "right",
                cursor: "pointer",
                marginRight: "20px",
              }}
            />
          </div>
          <ScrollableContent
            className="content"
            style={webStyle.modalContent as any}
          >
            <span style={webStyle.headerTitle as any}>
              Make an Offer
            </span>
            <span style={webStyle.offerSpan as any}>
              Are you sure you want to make an offer?
            </span>
            <div
              style={{
                width: "360px",
                height: "90px",
                display: "flex",
                gap: "5px",
                background:"rgba(255, 255, 255, 1)",
                borderRadius:"4px",
                alignItems:"center",
                justifyContent:"center"
              }}
            >
              <img
                height={70}
                width={70}
                src={productData?.catalogue?.attributes?.attachments[0]?.attributes?.high_media_url}
                alt=""
              />
              <div style={{ display: "flex", flexDirection: "column",boxSizing:"border-box",paddingRight:"12px",paddingLeft:"12px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between",width:"100%" ,gap:"7px"}}
                >
                  <span
                    style={{
                      fontFamily: "'Montserrat', sans-serif",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "32px",
                      letterSpacing: "-0.11999999731779099px",
                      textAlign: "left",
                    }}
                  >
                    {productData?.catalogue?.attributes?.brand_name}
                  </span>
                  <span
                    style={{
                      fontFamily: "'Montserrat', sans-serif",
                      fontSize: "18px",
                      fontWeight: 700,
                      lineHeight: "32px",
                      letterSpacing: "-0.11999999731779099px",
                      textAlign: "right",
                    }}
                  >
                   {productData?.catalogue?.attributes?.price}
                  </span>
                </div>
                <span
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "14.63px",
                    textAlign: "left",
                    width: "206px",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {productData?.catalogue?.attributes?.description?.replace(/<\/?[^>]+(>|$)/g, "")}
                </span>
              </div>
            </div>
            <div
              className="offer-section"
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <span    style={{
                      fontFamily: "'Montserrat', sans-serif",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "32px",
                      letterSpacing: "-0.11999999731779099px",
                      textAlign: "left",
                    }}>Your Offer</span>
              <MyTextField onChange={(e:any)=>handleChange(e)} value={offerValue} placeholder="..." />
                {offerErr && <p style={{color:"red",margin:"0px"}}>{offerErr}</p>}
            </div>
            <FooterButton
              style={{
                marginTop: "30px",
                marginBottom: "15px",
                margin: "auto",
              }}
              onClick={handleSubmit}
            >
              Submit
            </FooterButton>
          </ScrollableContent>
        </Box>
      </Modal>
    </>
  );
};

export default MakeOffer;

const webStyle = {
  headerCss: {
    display: "flex",
    justiyContent: "space-between",
  },
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxSizing: "border-box",
    backgroundColor: "rgba(239, 238, 233, 1)",
  },
  headerTitle: {
    fontFamily: "'Montserrat', sans-serif",
    color: "rgba(83, 80, 63, 1)",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "-0.11999999731779099px",
    textAlign: "left",
  },
  modalContent: {
    fontFamily: "'Montserrat', sans-serif",
    // fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    color: "rgba(83, 80, 63, 1)",
    display: "flex",
    alignItems: "center",
    gap: "35px",
  },
  footerDiv: {
    textAlign: "center",
    width: "100%",
    paddingTop: "20px",
    paddingBottom: "75px",
  },
  offerSpan: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "34px",
    letterSpacing: "-0.11999999731779099px",
    textAlign: "center",
  },
};
