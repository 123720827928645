export const rightIcon=require('../assets/rightIconSvg.svg').default
export const crossIcon=require('../assets/crossIcon.svg').default
export const addIcon=require('../assets/addIcon.svg').default
export const locationIcon=require('../assets/location.svg').default
export const checkedRadio=require('../assets/selectedRadio.png').default
export const uncheckedRadio=require('../assets/unSelectedRadion.png').default
export const lockIcon=require('../assets/lockIcon.png').default
export const shoppingTrollyIcon=require('../assets/shoppingTrollyIcon.svg').default
export const visaIcon=require('../assets/visaIcon.svg').default
export const klarnaIcon=require('../assets/klarna.svg').default
export const group_maestro=require('../assets/maestroIcon.svg').default
export const paypalIcon=require('../assets/paypalIcon.svg').default
export const googlepayIcon=require('../assets/googlePayIcon.svg').default
export const applepayIcon=require('../assets/applePayIcon.svg').default
export const bagIcon=require('../assets/bagIcon.png').default
export const heartIcon=require('../assets/heartIcon.png').default
export const searchIcon=require('../assets/searchIcon.svg').default
export const checkBoxIcon=require('../assets/checkbox_.png').default
export const checkedIcon=require('../assets/checkedIcon.png').default

















