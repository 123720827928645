import React from "react";

// Customizable Area Start
import {
  createTheme,
} from "@mui/material/styles";
import ForgotPasswordController, {
    Props,
  } from "./ForgotPasswordController";
  import { styled } from "@mui/system";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const FooterButton = styled("button")({
  width: "360px",
  height: "56px",
  padding: "16px 0px",
  borderRadius: "8px",
  color: "white",
  border: "none",
  backgroundColor: "#53503F",
  fontFamily: "'Avenir-Black','Poppins-Light', sans-serif",
  fontSize: "18px",
  fontWeight: 800,
  cursor: "pointer",
});
// Customizable Area End

export default class NewPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
     // Customizable Area Start
      // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
    <span>new password</span>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  logo: {
    padding: "10px",
  },
  formMain: {
    width: "100%",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px",
    backgroundColor: "#EFEEE9",
    borderRadius: 8,
  },
  inputField: {
    marginTop: "5px",
    marginBottom: "10px",
    backgroundColor: "#fff",
    border: "none",
  },
  passwordField: {
    position: "relative",
  },
  eyeIconMainWithOutError: {
    position: "absolute",
    right: "15px",
    bottom: "40%",
  },
  remember: {
    display: "flex",
  },
  rememberMain: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  welcome: {
    margin: "10px",
  },
  eyeIconWithError: {
    position: "absolute",
    right: "15px",
    bottom: "56%",
  },
  fieldLabel: {
    fontFamily: "'Avenir-Black','Poppins-Light', sans-serif",
    fontSize: "10px",
    fontWeight: "800",
    lineHeight: "24px",
    textAlign: "left",
    color: "#53503F",
  },
  welcomeText: {
    fontFamily: "'Avenir-Black','Poppins-Light', sans-serif",
    fontSize: "20px",
    fontWeight: "400",
    color: "#53503F",
  },
  btn: {
    backgroundColor: "#53503F",
    color: "#fff",
    marginTop: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    textTransform: "none",
    width: "100%",
    "&:hover": {
      backgroundColor: "#403D2F",
      boxShadow: "none",
    },
  },
  error: {
    margin: "0px",
    fontSize: "12px",
    fontWeight: 800,
    color: "red",
    paddingBottom: "15px",
  },
  inpLable: {
    fontFamily: "'Avenir-Black','Poppins-Light', sans-serif",
    fontSize: "16px",
    fontWeight: 800,
    lineHeight: "24px",
    textAlign: "left",
  },
};
// Customizable Area End