import React from "react";

// Customizable Area Start
import EmailAccountRegistrationSellerController, {
  Props,
  configJSON
} from "./EmailAccountRegistrationSellerController.web"
import {
  createTheme,
} from "@mui/material/styles";
import { eyeOpenIcon, eyeCloseIcon } from "./assets";
import {Box, Grid, InputLabel, TextField, Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { MONTSERRAT_THIN } from '../../../components/src/fontConstants.web';
import Select from '@mui/material/Select';
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#ffffff",
    },
  },
});
// Customizable Area End

export default class EmailAccountRegistrationSeller extends EmailAccountRegistrationSellerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInputField(
    placeholder: string, 
    label: string, 
    value: string | undefined, 
    name: string, 
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    dataTestId: string,
    error: string
  ) {
    return (
      <Grid item lg={6} md={4} sm={4} xs={12}>
        <InputLabel className="montserratMediumGoogle">{label}</InputLabel>
        <TextField
          sx={{
            "& fieldset": { border: 'none' },
            '& .MuiInputBase-input::placeholder': {
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              color: '#53503F',
            },
            '& .MuiInputBase-input': {
              color: '#53503F',
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                border: '1px solid #53503F',
              },
            },
            
          }}
          fullWidth
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          style={styles.inputField}
          data-testid={dataTestId}
        />
        {error ? (
          <p style={styles.error} id='emailError' className='montserratLightError'>
            {error}
          </p>
          ) : null
        }
        {name === 'workEmail' && this.state.emailBackendError ? (
          <p style={styles.error} id='emailError' className='montserratLightError'>
            Email {this.state.emailBackendError}
          </p>
          ) : null
        }
        
        {name === 'number' && this.state.phoneBackendError ? (
          <p style={styles.error} id='emailError' className='montserratLightError'>
            {this.state.phoneBackendError}
          </p>
          ) : null
        }
      </Grid>
    );
  }

  renderPasswordField(
    name: string,
    placeholder: string, 
    label: string, 
    value: string, 
    dataTestId: string, 
    eyeState: boolean, 
    handleEyeClick: () => void, 
    eyeIconTestId: string,
    handleChange: any,
    error: string
  ) {
    return (
      <Grid item lg={6} md={4} sm={4} xs={12}>
        <InputLabel className="montserratMediumGoogle">{label}</InputLabel>
        <Box sx={styles.passwordField}>
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  border: '1px solid #53503F',
                },
              },
              "& fieldset": { border: 'none' },
              '& .MuiInputBase-input::placeholder': {
                fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                color: '#53503F',
              },
              '& .MuiInputBase-input': {
                fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                color: '#53503F',
              },
            }}
            name={name}
            fullWidth
            value={value}
            onChange={handleChange}
            style={styles.inputField}
            placeholder={placeholder}
            type={eyeState ? 'text' : 'password'}
            data-testid={dataTestId}
          />
          <Box
            data-testid={eyeIconTestId}
            onClick={handleEyeClick}
            sx={styles.eyeIconMainWithOutError}
          >
            <img src={eyeState ? eyeOpenIcon : eyeCloseIcon} alt="eyeIcon" />
          </Box>
        </Box>
        {error ? (
            <p style={styles.error} id='emailError' className='montserratLightError'>
              {error}
            </p>
            ) : null
          }
      </Grid>
    );
  }
  // Customizable Area End

  render() {
     // Customizable Area Start
    const { firstUserName, lastUserName, workEmail, number, password, confirmPassword,location } = this.state;
      // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <>      
      <Box sx={styles.root}>
        <Box style={styles.welcome}>
          <label style={styles.welcomeText} className="montserratFont">Create Account</label>
        </Box>
        <Box style={styles.formMain}>
          <form>
            <Grid container spacing={2}>
            {this.renderInputField(
              configJSON.labelFirstName,
              configJSON.labelFirstName,
              this.state.firstUserName,
              'firstName',
              this.handleFirstUserName,
              'firstNameTestId',
              this.state.firstUserNameError
            )}
            {this.renderInputField(
              configJSON.lastName,
              configJSON.lastName,
              this.state.lastUserName,
              'lastName',
              this.handleLastUserName,
              'lastNameTestId',
              this.state.lastUserNameError
            )}
            {this.renderInputField(
              configJSON.workEmailLabel,
              configJSON.workEmailLabel,
              this.state.workEmail,
              'workEmail',
              this.handleWorkEmail,
              'workEmailTestId',
              this.state.workEmailError
            )}
            {this.renderInputField(
              configJSON.phoneNumber,
              configJSON.phoneNumber,
              this.state.number,
              'number',
              this.handlePhoneNumber,
              "numberTestId",
              this.state.numberError
            )}
            {this.renderPasswordField(
              'password',
              configJSON.labelPassword,            
              configJSON.labelPassword,            
              this.state.password,                
              'passwordTestId',                   
              this.state.eyeIconPassword,         
              this.handlePasswordEye,             
              'eyeIconPasswordTestId',
              this.handlePassword,
              this.state.passwordError                        
            )}
            {this.renderPasswordField(
              'confirmPassword',
              configJSON.confirmPassword,          
              configJSON.confirmPassword,          
              this.state.confirmPassword,         
              'confirmPasswordTestId',            
              this.state.eyeIconConfirm,          
              this.handleConfirmPasswordEye,             
              'eyeIconConfirmPassTestId',
              this.handleConfirmPassword,
              this.state.confirmPasswordError       
            )}
            <Grid item lg={6} md={4} sm={4} xs={12}>
              <InputLabel className="montserratMediumGoogle">{configJSON.locationText}</InputLabel>
              <FormControl fullWidth sx={styles.selectField}>
              <Select
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                value={this.state.location}
                onChange={this.handleLocationChange}
                displayEmpty
                sx={{
                  ...styles.locationDropdown,
                  '& .MuiSelect-select': {
                    fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                    color: '#53503F',
                  },
                }}
                data-testid="locationTestId"
                name="location"
                MenuProps={{
                  PaperProps: {
                    style: {
                      border: '1px solid #53503F',
                      width: '100px',
                      minWidth: '200px',
                      height: '180px',
                    },
                  },
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom', 
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                }}
              >
                <MenuItem value="" sx={{
                fontFamily: 'Montserrat, sans-serif',
                '&.Mui-selected:hover': {
                  backgroundColor: '#5b5b5b',
                },
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#80806e',  
                },
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
                }}>
                  <em style={styles.selectPlaceholder}>Select</em>
                </MenuItem>
                {Array.isArray(this.state.locationData) && this.state.locationData.map((locate) => 
                  <MenuItem value={locate.attributes.id}>{locate.attributes.name}</MenuItem>
                )}
              </Select>
            </FormControl>
            {this.state.locationError ? (
              <p style={styles.error} id='locationError1' className='montserratLightError'>
                {this.state.locationError}
              </p>
              ) : null
            }
            </Grid>
            </Grid>  
            <Grid item lg={6} md={4} sm={4} xs={12}>
              {this.state.backendError ? (
                <p style={styles.error} id='backendError' className='montserratLightError'>
                  {this.state.backendError }
                </p>
                ) : null
              }
            </Grid>
            <Box style={styles.createAccount}>
              <Box style={styles.buttonStyle}>
                <Button variant="contained" data-testid="cancelBtnTestId" onClick={this.handleCancelBtn} sx={styles.btnCancel}>Cancel</Button>
                <Button 
                disabled= {!firstUserName || !lastUserName || !workEmail || !number || !password || !confirmPassword || !location}
                data-testid='nextTestId' onClick={this.handleNextBtn} variant="contained" sx={styles.btn}>
                  Next
                </Button>
              </Box>
            </Box>
          </form>
        </Box>       
      </Box>
      </>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  welcome: {
    paddingBottom: '15px',
    margin: '10px',
  },
  selectPlaceholder: {
    color: '#B0B0B0',
    fontSize: 'inherit',
    fontStyle: 'normal',
    display: 'block'
  },
  previewDoc: {
    position: "relative",
  },
  embed: {
    width: '100px',
    height: '100px'
  },
  imageUploaded: {
    height: '100px',
    width: '100px',
  },
  closeIcon: {
    position: 'absolute',
    top: '0px',
    right: '0px'
  },
  imageSupport: {
    fontSize: '12px',
    margin: '10px 0px'
  },
  dragText: {
    fontSize: '14px'
  },
  dropzone: {
    border: '1px dashed #000000',
    padding: '10px',
  },
  formMain: {
    width: '100%'
  },
  inputField: {
    marginTop: '5px',
    marginBottom: '10px',
    backgroundColor: '#ffffff',
    border: 'none',
  },
  passwordField: {
    position: 'relative'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: '#EFEEE9', 
    borderRadius: 8,
  },
  logo: {
    padding: '10px'
  },
  fieldLabel: {
    fontFamily: "Avenir",
    fontSize: '10px',
    lineHeight: '24px',
    fontWeight: 800,
    textAlign: "left",
    color: '#53503F'
  }, 
  remember: {
    display: 'flex',
  },
  eyeIconMainWithOutError: {
    position: 'absolute',
    right: '15px',
    bottom: '40%'
  },
  eyeIconWithError: {
    position: 'absolute',
    right: '15px',
    bottom: '56%'
  },
  rememberMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  createAccount: {
    margin: 'auto',
    width: '100%'
  },
  btn: {
    backgroundColor: '#53503F',
    color: '#ffffff',
    marginTop: '10px',
    paddingTop: '15px',
    paddingBottom: '15px',
    textTransform: 'none',
    width: '195px',
    '&:hover': {
      backgroundColor: '#403D2F',
      boxShadow: 'none',
    },
    '.MuiButtonBase-root-MuiButton-root': {
      padding: '100px'
    }
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 70px',
    gap: '25px'
  },
  btnCancel: {
    backgroundColor: 'transparent',
    color: '#53503F',
    marginTop: '10px',
    paddingTop: '15px',
    paddingBottom: '15px',
    boxShadow: 'none',
    textTransform: 'none',
    border: '1px solid #53503F',
    width: '195px',
    '&:hover': {
      backgroundColor: '#403D2F',
      boxShadow: 'none',
      color: '#ffffff'
    },
    '.MuiButtonBase-root-MuiButton-root': {
      padding: '100px'
    }
  },
  welcomeText: {
    fontFamily: "Avenir",
    fontSize: '20px',
    fontWeight: 400,
    color: '#53503F'
  },
  error: {
    margin: '0px',
    fontSize: "12px",
    color: '#DC2626',
    fontWeight: 800,
    paddingBottom: '15px'
  },
  selectField: {
    marginTop: '5px',
    marginBottom: '10px',
    backgroundColor: '#f0f0f0', 
    '&:focus': {
        backgroundColor: '#f0f0f0',
    },
  },
  radioField: {
    marginTop: '5px',
    marginBottom: '10px',
    backgroundColor: '#f0f0f0', 
    '&:focus': {
        backgroundColor: '#f0f0f0',
    },
  },
  toggleMain: {
    gap: '15px',
    marginTop: '5px',
    marginBottom: '10px'
  },
  toggleBtn: {
    border: '2px solid #53503F !important', 
    borderRadius: '8px !important',
    color: '#53503F', 
    '&.Mui-selected': {
      backgroundColor: '#53503F', 
      color: '#ffffff',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#53503F',
    },
  },
  locationDropdown: {
      backgroundColor: '#ffffff',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
  },
  termCondition: {
    display: 'flex',
    color: '#53503F'
  },
  termBold: {
    fontWeight: 800,
    textDecoration: 'underline'
  },
  uploadBoxMain: {
    borderStyle: 'dashed',
    border: '2px solid #53503F',
  }
};
// Customizable Area End