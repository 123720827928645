import React from "react";

// Customizable Area Start
import FilterController, {
    Props,
} from "./FilterController.web";
import { Drawer, Slider,Typography,TextField,Chip, FormControl, Button, Select, MenuItem, Autocomplete } from '@mui/material';
import { Box } from '@mui/system';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class FilterWeb extends FilterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderValueLabel = (value: number) => {
    return `$${value}`;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { priceRange, year, color, size, searchTerm, filteredBrands } = this.state;

    // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <Drawer
        anchor="left"
        variant="persistent"
        open={true}
        onClose={() => this.props.handleFilterClose()}
        PaperProps={{ sx: webStyle.drawerPaper }}
        data-testid="filterTestId"
      >
        <Box sx={webStyle.header}>
          <Typography variant="h6">Filters</Typography>
          <Button 
          color="error" 
          data-testid="clearBtnTestId" 
          onClick={() => this.setState({ brand: 0, subCategory: [] })}
          >
            Clear All
          </Button>
        </Box>

        <Box>
          <Typography>Price Range</Typography>
          <Slider
            value={priceRange}
            step={1}
            valueLabelFormat={this.renderValueLabel}
            onChange={this.handlePriceChange}
            min={this.state.minPriceData}
            max={this.state.maxPriceData}
            valueLabelDisplay="on"
            sx={webStyle.slider}
            name="priceSlide"
            data-testid="priceSliderTestId"
          />
        </Box>

        <Box mt={2}>
        <Typography>Brand</Typography>
        <FormControl variant="outlined" fullWidth>
        <Autocomplete
          data-testid="brandTestId"
          value={filteredBrands.find((brand: any) => brand.name === searchTerm) || null}
          onInputChange={(event, newInputValue) => this.handleSearchChange(newInputValue)}
          options={filteredBrands}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Search Brands" variant="outlined" />}
          onChange={(event, value) => this.handleSelectBrand(value)}
          noOptionsText="No brands found"
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          fullWidth
        />
        </FormControl>
        </Box>

        <Box mt={2}>
          <Typography>Subcategory</Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {this.state.subCategoryData.map((subCat: any) => (
              <Chip
              data-testid="subcategoryTestId"
              key={subCat.id}
              label={subCat.name}
              onClick={() => this.handleSubCategorySelect(subCat.id)}
              sx={{
                ...this.state.subCategory.includes(subCat.id) ? webStyle.chipSelected : webStyle.chipDefault,
                borderRadius: 0,
                padding: '10px 16px', 
                height: '40px', 
              }}
              clickable
            />
            ))}
          </Box>
        </Box>

        <FormControl fullWidth margin="normal">
          <Typography>Year of production</Typography>
          <Select
            sx={{color: '#53503F'}}
            data-testid="yearTestId"
            value={year}
            onChange={(e) => this.setState({ year: e.target.value })}
          >
            {this.state.productionYearData.map((year: any) => 
              <MenuItem value={year.product_year_production}>{year.product_year_production}</MenuItem>
            )}
          </Select>
        </FormControl>

        <Box display="flex" flexDirection="column" marginTop={2}>
          <Typography>Date Posted</Typography>
          <Box display="flex" justifyContent="space-between">
            <Button
              data-testid="recentPostBtn"
              variant="outlined"
              onClick={() => this.setState({ datePosted: 'recent' })}
              sx={{
                backgroundColor: this.state.datePosted === 'Recent' ? '#70624D' : '#FFFFFF',
                color: this.state.datePosted === 'Recent' ? '#FFFFFF' : '#3D3D3D',
                borderColor: '#3D3D3D',
                fontWeight: 'bold',
              }}
            >
              Recent
            </Button>
            <Button
              data-testid="oldPostBtn"
              variant="outlined"
              onClick={() => this.setState({ datePosted: 'older' })}
              sx={{
                backgroundColor: this.state.datePosted === 'Old' ? '#70624D' : '#FFFFFF',
                color: this.state.datePosted === 'Old' ? '#FFFFFF' : '#3D3D3D',
                borderColor: '#3D3D3D',
                fontWeight: 'bold',
              }}
            >
              Old
            </Button>
          </Box>
        </Box>

        <FormControl fullWidth margin="normal">
        <Typography>Select Color</Typography>  
          <Select
            data-testid="colorChoiceTestId"
            value={color}
            onChange={(e) => this.setState({ color: e.target.value })}
          >
            {this.state.selectColorData.map((color: any) =>             
            <MenuItem value={color.id}>
              <Box component="span" sx={{ backgroundColor: color.color_code, width: 16, height: 16, display: 'inline-block', borderRadius: '50%' }} />
              &nbsp; {color.name}
            </MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Typography>Size</Typography>
          <Select
            data-testid="sizeTestId"
            value={size}
            onChange={(e) => this.setState({ size: e.target.value })}
          >
            {this.state.sizeData.map((data: any) => <MenuItem value={data.id}>{data.name}</MenuItem>)}
          </Select>
        </FormControl>

        <Box sx={webStyle.buttonContainer}>
          <Button 
          variant="outlined" 
          data-testid="cancelBtnTestId"
          onClick={() => this.props.handleFilterClose()} 
          sx={webStyle.cancelButton}>
            Cancel
          </Button>
          <Button 
          variant="contained" 
          data-testid='applyTestId'
          sx={webStyle.applyButton} 
          onClick={this.handleApplyButton}>
            Apply
          </Button>
        </Box>
      </Drawer>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  drawerPaper: {
    padding: 1,
    maxWidth: '500px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#3D3D3D',
  },
  slider: {
    color: '#3D3D3D',
  },
  chipSelected: {
    backgroundColor: '#70624D',
    color: '#FFFFFF',
  },
  chipDefault: {
    backgroundColor: '#FFFFFF',
    color: '#3D3D3D',
    border: '1px solid #3D3D3D',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  applyButton: {
    backgroundColor: '#70624D',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#5a4f3d',
    },
  },
  cancelButton: {
    borderColor: '#70624D',
    color: '#70624D',
  },
};
// Customizable Area End