import React from "react";

// Customizable Area Start
import CategoriesController, {
    Props,
  } from "./CategoriesController.web";
import { HeartIconOutlined } from "./assets";
import { Grid, Card, CardContent, CardMedia, Typography, IconButton, Box, Button,Select, MenuItem, FormControl, InputLabel  } from '@mui/material';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class CategoriesWeb extends CategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <>
      <Box sx={webStyle.categoryMain} data-testid="categoryTestId">
        <Box display="flex" justifyContent="space-between" p={2}>
          <Typography>Navigation</Typography>
          <Typography>Result count</Typography>
        </Box>
        <Box sx={webStyle.categoryBtn} p={2}>
          <Button 
          sx={webStyle.darkBtn} 
          onClick={this.props.allFilter}
          data-testid='allFilterTestId'
          >
            All Filters
          </Button>
          
          <Button 
          data-testid="womenTestId"
          sx={this.state.sortingByWomen ? webStyle.darkBtn : webStyle.transparentBtn} 
          onClick={this.handleWomenFilter}>
            Women
          </Button>

          <Button 
          data-testid="menTestId"
          sx={this.state.sortingByMen ? webStyle.darkBtn : webStyle.transparentBtn} 
          onClick={this.handleMenFilter}>
            Men
          </Button>
          
          <FormControl variant="outlined" style={webStyle.formControl}>
            <InputLabel style={webStyle.inputLabel}>Sort By:</InputLabel>
            <Select
              name="sortOption"
              value={this.state.sortOption}
              data-testid="sortOptionTestId"
              onChange={this.handleSortOption}
              label="Sort By:"
              style={webStyle.select}
              MenuProps={{
                PaperProps: {
                  style: webStyle.menuProps,
                },
              }}
            >
              <MenuItem 
              value="recent" 
              data-testid="recentBtnTestId"
              style={webStyle.menuItem} 
              onClick={this.handleRecentFilter}>
                Recent
              </MenuItem>
              <MenuItem 
              value="productionYear" 
              data-testid="productYearTestId"
              style={webStyle.menuItem} 
              onClick={this.handleProductionYearFilter}
              >
                Production Year
              </MenuItem>
              <MenuItem value="dateOfListing" style={webStyle.menuItem}>
                Date of Listing
              </MenuItem>
              <MenuItem 
              value="priceHighToLow" 
              data-testid="priceHightToLowTestId"
              style={webStyle.menuItem} 
              onClick={this.handlePriceHighToLow}
              >
                Price: High to Low
              </MenuItem>
              <MenuItem 
              value="priceLowToHigh" 
              data-testid="priceLowToHighTestId"
              style={webStyle.menuItem} 
              onClick={this.handlePriceLowToHigh}
              >
                Price: Low to High
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Grid container spacing={5} p={2} sx={{backgroundColor: "#EDE6DC"}}> 
      {this.state.product.length > 0 ?
      this.state.product.map((showProduct: any, index: any) => ( 
        <>
        <Grid item xs={12} sm={6} md={3} key={index}> 
          <Card sx={webStyle.cardMain} onClick={()=>this.navigateToDetailedProductView(showProduct.attributes.catalogue_id)}> 
            <Box sx={webStyle.cardMediaMain}>
              <CardMedia
                component="img"
                image={showProduct.attributes.image_url} 
                alt='productName'
                sx={webStyle.cardImageMain}
              />
              <IconButton
                sx={webStyle.wishlistIcon}
                aria-label="add to wishlist"
              >
                <img src={HeartIconOutlined} alt="heart"/>
              </IconButton>
            </Box>
            <CardContent sx={webStyle.cardTypography}> 
              <Typography variant="h6" sx={{ margin: 0 }}>{showProduct.attributes.name}</Typography> 
              <Typography variant="body2" sx={{ margin: 0 }} color="text.secondary"> 
                Category
              </Typography> 
              <Typography variant="body1" sx={{ margin: 0 }}>${showProduct.attributes.price}</Typography> 
            </CardContent> 
          </Card> 
        </Grid> 
        </>
      )) : <p>{this.state.filterBackendError}</p>}
      </Grid> 
    </>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  categoryMain: {
    backgroundColor: "#EDE6DC",
  },
  categoryBtn: {
    display: 'flex',
    gap: '15px'
  },
  transparentBtn: {
    border: '1px solid #53503F',
    color: '#53503F',
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "capitalize",
    borderRadius: "8px"
  },
  darkBtn: {
    border: '1px solid #53503F',
    backgroundColor: '#53503F',
    color: '#ffffff',
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "capitalize",
    borderRadius: "8px"
  },
  cardMain: {
    maxWidth: '100%', 
    boxShadow: 'none',
    border: 'none',
    cursor:"pointer"
  },
  cardMediaMain: {
    position: 'relative'
  },
  cardImageMain: {
    width: 200,
    height: 300,
    objectFit: 'cover',
    margin: '16px auto',
    borderRadius: 4,
  },
  wishlistIcon: {
    position: 'absolute',
    top: 0,
    right: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
  },
  cardTypography: {
    backgroundColor: '#EDE6DC', 
    margin: 0, 
    padding: '10px 0px'
  },
  formControl: {
    minWidth: 160,
    backgroundColor: '#f2f2e6',
    borderRadius: 8,
    padding: '4px 8px',
    color: '#8c8c8c',
    fontSize: '14px',
  },
  inputLabel: {
    color: '#8c8c8c',
  },
  select: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#333',
    backgroundColor: '#f2f2e6',
  },
  menuItem: {
    fontSize: '14px',
    color: '#333',
    padding: '10px 20px',
  },
  menuProps: {
    borderRadius: 8,
    backgroundColor: '#f2f2e6',
  },
};
// Customizable Area End