import React from "react";

// Customizable Area Start
import TermsAndPolicyModal from "../../../components/src/TermsAndPolicyPopUp.web";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div data-test-id="termsCondsList">
      <TermsAndPolicyModal
      openModal={this.props.openModal}
      handleClose={this.props.handleClose}
       label={this.props?.label} navigation={undefined} id={""}/>
      </div>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {

}
// Customizable Area End
