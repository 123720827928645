//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TextField,
  // Customizable Area End
  Grid,

} from "@mui/material";
// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  styled,
  StyledEngineProvider,
} from "@mui/material/styles";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import FullWidthTabComponent from "../../../components/src/LandingPageTabList";
import { BrandList } from "../../../components/src/BrandList.web";
import SearchIcon from "@mui/icons-material/Search";
import Link from '@mui/material/Link';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CharityPolicy from "../../../components/src/popups/CharityPolicyPopUp.web";
import MakeOffer from "../../../components/src/popups/MakeOfferPopUp.web";
import OfferSuccessPopUp from "../../../components/src/popups/OfferSuccessPopUp.web";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import Select from 'react-select'

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  }
);
const MyTextField = styled(TextField)(({ theme }) => ({
  width: "480px",
  boxSizing: "border-box",
  height: "44px",
  justifyContent: "center",
  padding: "10px 8px",
  gap: 8,
  borderRadius: "25px",
  borderWidth: "0.2px 0 0 0",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  margin:"25px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      width: "100%",
    },
  },
  [theme.breakpoints.down('md')]: {
    width: "300px",
  },
  [theme.breakpoints.down('sm')]: {
    width: "100%",
  },
}));
const FooterButton = styled("button")({
  width: "84px",
  height: "36px",
  padding: "16px 0px",
  borderRadius: "8px",
  color: "white",
  border: "none",
  backgroundColor: "#53503F",
  fontFamily: `"Montserrat", sans-serif`,
  fontSize: "12px",
  fontWeight: 400,
  cursor: "pointer",
  display:"flex",
  justifyContent:"center",
  alignItems:"center"
});
const CustomSelect = styled(Select)`
  max-width: 240px;
  border-radius: 8px;
  height: 56px;
  
  /* Control Styling */
  .react-select__control {
    min-height: 56px;
    height: 56px;
  }

  /* Value Container Styling */
  .react-select__value-container {
    height: 56px;
    padding: 0 8px;
  }

  /* Optionally hide the indicator separator */
  .react-select__indicator-separator {
    display: none;
  }
`;
import {productBanner,charityHandImg,cartIcon,exportIcon} from "./assets";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
const breadcrumbs = [
  <Link
  style={{ color:"rgba(83, 80, 63, 1)",fontWeight:400}}
   underline="hover" key="1" color="inherit" href="/" >
    Home
  </Link>,
  <Link
  style={{ color:"rgba(83, 80, 63, 1)",fontWeight:400}}
    underline="hover"
    key="2"
    color="inherit"
    href="/material-ui/getting-started/installation/"
  >
    Watches
  </Link>,
  <Typography key="3" sx={{ color:"rgba(83, 80, 63, 1)",fontWeight:600 }}>
    Details
  </Typography>,
];
const ColorOption = (props:any) => {
  return (
    <div
      {...props.innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px"
      }}
    >
      <div
       style={{
        backgroundColor: props.data.colorCode,
        width: "28px",
        height: "28px",
        borderRadius: "6px",
        marginRight: "8px",
        border: "1px solid rgba(83, 80, 63, 1)"
      }}
      />
      <span style={{fontFamily: `"Montserrat", sans-serif`,
fontSize: "16px",
fontWeight: 600,
lineHeight: "22px",
textAlign: "left",
color:"rgba(83, 80, 63, 1)"
}}>{props.data.label}</span>
    </div>
  );
};
function stripHTMLTags(input) {
  if (!input || typeof input !== 'string') return '';
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');
  return doc.body.textContent || doc.body.innerText || '';
}

const SingleValue = (props:any) => {
  return (
    <div style={{ display: "flex",justifyContent:"center", alignItems: "center" }}>
      <div style={{width:"35px",height:"35px",border: "1px solid rgba(83, 80, 63, 1)",borderRadius:"6px",display:"flex",justifyContent:"center",alignItems:"center"}}>

      <div
        style={{
          backgroundColor: props.data.colorCode,
          width: "28px",
          height: "28px",
          borderRadius: "6px",
          margin: "auto",
        }}
        />
        </div>
      <span style={{fontFamily: `"Montserrat", sans-serif`,
fontSize: "16px",
fontWeight: 600,
lineHeight: "22px",
textAlign: "left",
color:"rgba(83, 80, 63, 1)",
marginLeft:"15px"
}}>{props.data.label}</span>
    </div>
  );
};
// Customizable Area End


import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div
        data-test-id="productContainer"
        style={{
          backgroundColor: "#EDE6DC",
          textAlign: "center",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <AppHeader />
        <FullWidthTabComponent 
            categoriesOnHoverList={this.state.categoriesOnHoverList}
            getCategoriesOnHover={this.getCategoriesOnHover}
            categoriesList={this.state.categoriesArray}
            handleCategories={this.handleCategoryData}
        />
        <div style={{ width: "100%", textAlign: "center" }}>
          <MyTextField
            placeholder="Search for.."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div
          className="productDetails"
          style={{
            boxSizing: "border-box",
            padding: "5px 40px",
            width: "100%",
          }}
        >
          <div className="breadcrumb" style={{marginBottom:"20px"}}>
            <Stack spacing={1}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{
                  '& .MuiBreadcrumbs-separator': {
                    marginLeft: '-1px',
                    marginRight:"3px"
                  },
                }}
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </div>
          <Grid
  container
  spacing={1}
  style={{
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
  }}
>
  <Grid
    container
    item
    md={8}
    lg={8}
    sm={12}
    // spacing={0}
    className="productDetails"
    style={{
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row", 
      gap:"56px",
      flexWrap: "nowrap",
    }}
  >
    {/* Product Images Grid */}
    <Grid
      container
      item
      md={1}
      lg={1}
      sm={2}
      xs={12} 
      className="productImages"
      style={{
        display: "flex",
        gap: "6px",
        flexDirection: "column", // Stacks images vertically on smaller screens
      }}
    >
      {this.state.productDescriptionData?.catalogue?.attributes?.attachments?.map((ele: any) => (
        <div style={{border: "1px solid rgba(83, 80, 63, 1)",padding:"3px",borderRadius:"4px"}}>
        <div
          data-test-id="bigImgId"
          style={{
            borderRadius: "4px",
            background: "rgba(255, 255, 255, 1)",
            width: "110px",
            height: "116px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ borderRadius: "4px" }}
            height={67}
            width={67}
            src={ele?.attributes?.high_media_url}
            alt=""
          />
        </div>
        </div>

      ))}
    </Grid>
    <Grid
  item
  md={10}
  lg={10}
  sm={12}
  xs={12}
  container
  className="bigImg"
  style={{
    background: "rgba(255, 255, 255, 1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    height: "90vh",
    maxHeight: "804px",
    position: "relative", // Add position relative to anchor the overlay
  }}
>
  {/* Overlay div in the top-right corner */}
  <div
    style={{
      position: "absolute",
      top: "20px",
      right: "20px",
      color: "white",
      padding: "4px 8px",
      borderRadius: "4px",
      fontSize: "12px",
      display:"flex",
      gap:"12px",
      alignItems:"center"
    }}
  >
    <FavoriteBorderIcon style={{color:"rgba(83, 80, 63, 1)",cursor:"pointer"}}/>
    <img style={{color:"rgba(83, 80, 63, 1)",width:"26px",height:"26px",cursor:"pointer"}} src={exportIcon} alt="exportIcon" />
  </div>

  <img
    style={{
      width: "100%",
      maxWidth: "406px",
      height: "auto",
      maxHeight: "406px",
    }}
    src={this.state.productDescriptionData?.catalogue?.attributes?.attachments?.[0]?.attributes?.high_media_url || ""}
    alt=""
  />
</Grid>

  </Grid>

  <Grid
            md={4}
            lg={4}
              container
              style={{
                flex: "1",
                textAlign: "left",
                flexWrap: "nowrap",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{paddingBottom:"10px",display:"flex",flexDirection:"column",gap:"4px",boxShadow: "0 0.5px 0 rgba(83, 80, 63, 1)"}}>

              <div
                item
                md={12}
                lg={12}
                sm={12}
                textAlign={"left"}
                style={{
                  fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "26px",
                  fontWeight: 600,
                  lineHeight: "32px",
                  letterSpacing: "-0.11999999731779099px",
                  textAlign: "left",
                  color:"rgba(83, 80, 63, 1)"
                }}
              >
                {this.state.productDescriptionData?.catalogue?.attributes?.name}
              </div>
              <div
                style={{
                  ...webStyle.textColor,
                  fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "30px",
                }}
              >
               {this.state.productDescriptionData?.catalogue?.attributes?.product_heading}
              </div>
              <div
                style={{
                  ...webStyle.textColor,
                  fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "30px",
                  fontWeight: 600,
                  lineHeight: "32px",
                  letterSpacing: "-0.11999999731779099px",
                  textAlign: "left",
                }}
              >
                {this.state.productDescriptionData?.catalogue?.attributes?.price}
              </div>
              </div>
              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  marginTop: "12px",
                }}
                style={{ display: "flex", marginTop: "12px" }}
              >
                {/* Color Selection */}
                <Grid 
                  item 
                  xs={12} 
                  md={6} 
                  style={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    gap: "15px" 
                  }}
                >
                  <span
                    style={{
                      ...webStyle.textColor,
                      fontFamily: `"Montserrat", sans-serif`,
                      fontSize: "18px",
                      fontWeight: 600,
                      lineHeight: "21.94px",
                      letterSpacing: "-0.12px",
                      textAlign: "left",
                    }}
                  >
                    Select Color*
                  </span>
                  <CustomSelect 
                    classNamePrefix="react-select" 
                    components={{ IndicatorSeparator: () => null, Option: ColorOption, SingleValue: SingleValue }} 
                    options={this.getColorOptions()} 
                  />
                </Grid>

                {/* Size Selection */}
                <Grid 
                  item 
                  xs={12} 
                  md={6} 
                  style={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    gap: "15px" 
                  }}
                >
                  <span
                    style={{
                      ...webStyle.textColor,
                      fontFamily: `"Montserrat", sans-serif`,
                      fontSize: "18px",
                      fontWeight: 600,
                      lineHeight: "21.94px",
                      letterSpacing: "-0.12px",
                      textAlign: "left",
                    }}
                  >
                    Select Size*
                  </span>
                  <CustomSelect 
                    classNamePrefix="react-select" 
                    components={{ IndicatorSeparator: () => null }} 
                    options={this.getSizeOptions()} 
                  />
                </Grid>
              </Grid>


               
                <div style={{marginTop:"15px",display:"flex",flexDirection:"column",gap:"4px"}}>
                <span style={{
                  color: "rgba(83, 80, 63, 1)",fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "21.94px",
                  letterSpacing: "-0.11999999731779099px",
                  textAlign: "left",
                }}>Description</span>
                <p style={{
                  fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  textAlign: "left",
                  color: "rgba(83, 80, 63, 1)",
                  marginTop:"-13px"
                }}/>
                {stripHTMLTags(this.state.productDescriptionData?.catalogue?.attributes?.description)}
                </div>
                <div className="addToCartBtn" style={{display:"flex",gap:"35px",marginTop:"12px"}}>
                <button style={{
                  width: "340px",
                  height: "56px",
                  borderRadius: "4px",
                  background:"rgba(83, 80, 63, 1)",
                  color:"rgba(237, 230, 220, 1)",
                  fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  gap:"14px",
                  border:"none",
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center",cursor:"pointer"
                }}
                data-test-id="addtoCart"
                onClick={this.addToCart}
                > <img src={cartIcon} height={24} width={24} alt="" />  Add to Cart</button>
                <button style={{
                  width: "157px",
                  background:"rgba(237, 230, 220, 1)",
                  height: "56px",
                  borderRadius: "4px",
                  color:"rgba(83, 80, 63, 1)",
                  fontFamily: `"Montserrat", sans-serif`,
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  cursor:"pointer",
                  border:"1px solid rgba(83, 80, 63, 1)"
                }} onClick={this.handleMakeOfferModal}>Make an Offer</button>
                </div>
                <ul className="point" style={{paddingLeft:"15px",marginTop:"15px"}}>
                  <li>Estimated Delivery: 2 Aug - 9 Aug</li>
                </ul>
                {this.state.offerSuccessModal && <OfferSuccessPopUp openModal={this.state.offerSuccessModal}handleClose={this.handleOfferSuccess}/>}
                {this.state.openOfferModal && <MakeOffer
                 handleChange={this.makeAnOfferChange}
                 offerValue={this.state.makeOffer}
                 offerErr={this.state.offerErr}
                 handleSubmit={this.makeAnOffer}
                 productData={this.state.productDescriptionData} openModal={this.state.openOfferModal} handleMakeOfferModal={this.handleMakeOfferModal} />}
                <div className="accordion">
                <Accordion style={{background:"rgba(237, 230, 220, 1)",marginTop:"16px",boxShadow:"none",border:"1px solid rgba(83, 80, 63, 1)"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontFamily: `"Montserrat", sans-serif`,
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "24px",
            textAlign: "left",
            color:"rgba(83, 80, 63, 1)"
            }}
        >
          More Details
        </AccordionSummary>
        <AccordionDetails>
       <hr style={{width:"100%",marginTop:"-14px"}} />
       <div style={{display:"flex",flexDirection:"column",gap:"21px",marginTop:"20px"}}>

       <div>Wear : {this.state.productDescriptionData?.catalogue?.attributes?.gender!=="female"?"Menswear":"WomensWear"}</div>
       <div>Category : {this.state.productDescriptionData?.catalogue?.attributes?.category_name}</div>
       <div>Sub Category :{this.state.productDescriptionData?.catalogue?.attributes?.gender}</div>
       <div>Brand : {this.state.productDescriptionData?.catalogue?.attributes?.brand_name}</div>
       <div>Condition : {this.state.productDescriptionData?.catalogue?.attributes?.product_condition}</div>
       <div>Material : Leather</div>
       <div>Use : {this.state.productDescriptionData?.catalogue?.attributes?.product_condition}</div>
       {/* <div>Water Resistance : Yes</div> */}
       <div>Year of Production : {this.state.productDescriptionData?.catalogue?.attributes?.product_year_production}</div>
       {/* <div>Packages : Original box</div>
       <div>Dust Bag : Yes</div> */}
       <div style={{fontFamily: `"Montserrat", sans-serif`,
fontSize: "16px",
fontWeight: 600,
lineHeight: "19.5px",
letterSpacing: "-0.11999999731779099px",
textAlign: "left",
color:"rgba(83, 80, 63, 1)"
}}>Certificates</div>
       </div>

        </AccordionDetails>
      </Accordion>
                </div>
                <div className="charity" style={{display:"flex",position:"relative",alignItems:"center",marginTop:"15px"}}>
                 <div style={{background:"rgba(237, 230, 220, 1)",border:"1px solid rgba(83, 80, 63, 1)",width:"49px",height:"49px",borderRadius:"50%",position:"relative",zIndex:99,display:"flex",justifyContent:"center",alignItems:"center"}}>
                 <img
                  src={charityHandImg}
                  width={28}
                  height={22}
                  />
                 </div>
                 
                    <div style={{boxSizing:"border-box",marginLeft:"-32px",height:"46px",paddingLeft:"40px",display:"flex",justifyContent:"space-between",border:"1px solid rgba(83, 80, 63, 1)",width:"100%",alignItems:"center"}}>
                      <span style={{
                        fontFamily: `"Montserrat", sans-serif`,
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "21.94px",
                        textAlign: "left",
                        
                      }}>Our Commitment to Charity</span>
                      <InfoOutlinedIcon  data-test-id="charityBtn"onClick={this.handleCharityModal} style={{cursor:"pointer",marginRight:"10px",fontWeight:"normal",color:"rgba(83, 80, 63, 1)"}}/>
                    </div>
                </div>
            </Grid>
</Grid>

        </div>

        <BrandList
          index={0}
          navigateToDetailedProductView={this.navigateToDetailedProductView}
          brandsList={this.state.featuredProducts}
          categoryLable={"Featured Products"}
          shwoBrandComponent={false}
          ViewAllLink={"/"}
          brandNameDesc={"Explore the latest collections from our most loved in house labels"}
        />

        <div
          style={{
            boxSizing: "border-box",
            padding: "0px 2.5rem",
            width: "100%",
          }}
        >
          <div
            style={{
              boxSizing: "border-box",
              padding: "0 40px",
              paddingBottom: "50px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${productBanner})`,
              height: "400px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              style={{
                fontFamily: "Big Caslon",
                fontSize: "45px",
                fontWeight: 500,
                lineHeight: "54px",
                letterSpacing: "0.25px",
                textAlign: "left",
                color: "white",
                marginLeft: "25px",
              }}
            >
              Luxury Goggles: Tommy Hilfiger
            </Typography>
            <Button
              sx={{
                fontFamily: `"Montserrat", sans-serif`,
                color: "rgba(237, 230, 220, 1)",
                // color: "black",
                width: "160px",
                height: "44px",
                marginLeft: "25px",
                borderRadius: "4px",
                border: "1px solid rgba(237, 230, 220, 1)",
                ":hover": {
                  background: "transparent",
                },
              }}
            >
              Shop Now
            </Button>
          </div>
        </div>
        <div
          style={{
            height: "333px",
            width: "100%",
            padding: "63px 6rem 0 6rem",
            background: "rgba(239, 238, 233, 1)",
            boxSizing: "border-box",
            display: "flex",
          }}
        >
          <div
            className="contactDiv"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "503px",
              textAlign: "left",
              height: "fit-content",
              marginRight: "92px",
              marginLeft: "50px",
            }}
          >
            <span
              style={{
                fontFamily: `"Montserrat", sans-serif`,
                fontSize: "22px",
                fontWeight: 500,
                lineHeight: "36px",
                textAlign: "left",
                ...webStyle.colorCss,
              }}
            >
              Contact Info
            </span>
            <div
              class="my-div"
              style={{ ...webStyle.assistText, ...webStyle.colorCss }}
            >
              We are always happy to <br /> assist you
            </div>
          </div>
          <div
            className="emailDiv"
            style={{
              marginRight: "210px",
              display: "flex",
              width: "246px",
              flexDirection: "column",
              textAlign: "left",
              gap: "15px",
            }}
          >
            <span style={{ ...webStyle.emailAdd, ...webStyle.colorCss }}>
              Email Address
            </span>
            <hr
              style={{
                width: "50px",
                marginLeft: "0px",
                ...webStyle.hrCss,
                ...webStyle.colorCss,
              }}
            />
            <span style={{ ...webStyle.eid, ...webStyle.colorCss }}>
              help@info.com
            </span>
            <span style={{ ...webStyle.timingsCss, ...webStyle.colorCss }}>
              Assistance hours:{" "}
            </span>
            <span style={{ ...webStyle.timingsCss, ...webStyle.colorCss }}>
              Monday - Friday 6 am to 8 pm EST
            </span>
          </div>
          <div
            className="orderDiv"
            style={{
              display: "flex",
              width: "246px",
              flexDirection: "column",
              textAlign: "left",
              gap: "15px",
            }}
          >
            <span style={{ ...webStyle.emailAdd, ...webStyle.colorCss }}>
              Order by Phone
            </span>
            <hr
              style={{
                width: "50px",
                marginLeft: "0px",
                ...webStyle.hrCss,
                ...webStyle.colorCss,
              }}
            />
            <span style={{ ...webStyle.eid, ...webStyle.colorCss }}>
              (808) 998-34256
            </span>
            <span style={{ ...webStyle.timingsCss, ...webStyle.colorCss }}>
              Assistance hours:{" "}
            </span>
            <span style={{ ...webStyle.timingsCss, ...webStyle.colorCss }}>
              Monday - Friday 6 am to 8 pm EST
            </span>
          </div>
        </div>
        {this.state.openCharityModal && <CharityPolicy data={this.state.charityPolicyData} openModal={this.state.openCharityModal} handleClose={this.handleCharityModal}/>}
        <Footer />
      </div> as ReactNode
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: `"Montserrat", sans-serif`,
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  colorCss: {
    color: "rgba(83, 80, 63, 1)",
  },
  fontMontserrat: {
    fontFamily: `"Montserrat", sans-serif`,
  },
  font500: {
    fontWeight: 500,
  },
  fontSize22: {
    fontSize: "22px",
  },
  fontSize32: {
    fontSize: "32px",
  },
  assistText: {
    fontFamily: "Big Caslon",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "41.6px",
    textAlign: "left",
  },
  emailAdd:{
    fontFamily: `"Montserrat", sans-serif`,
fontSize: "20px",
fontWeight: 600,
lineHeight: "24.38px",
textAlign: "left",

  },
  eid:{
    fontFamily: `"Montserrat", sans-serif`,
fontSize: "18px",
fontWeight: 600,
lineHeight: "21.94px",
textAlign: "left",

  },
  timingsCss:{
    fontFamily: `"Montserrat", sans-serif`,
fontSize: "16px",
fontWeight: 400,
lineHeight: "32px",
textAlign: "left",
  },
  hrCss:{
    border:"3px solid rgba(83, 80, 63, 1)",
    width:"27px"
  },
  textColor:{
    color:"rgba(83, 80, 63, 1)"}
};
// Customizable Area End
