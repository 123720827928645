import React, { useRef, useState, useEffect } from 'react';
import { Typography, styled } from "@mui/material";
import { SingleProductComponent } from './SingleProductLandingPage.web'; // Adjust the path if needed
const rightArrow = require("./socialmediaicons/svgviewer-output (1).svg").default;

const Container = styled('div')({
  margin: "60px 0",
});

const BrandHeader = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 40px",
  width:"100%",
  boxSizing:"border-box",
  position:"relative"
});

const BrandName = styled(Typography)({
  fontFamily: "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular', serif !important",
  fontSize: "35px",
  fontWeight: "500",
  lineHeight: "42.31px",
  color: "#53503F",
});

const CategoriesContainer = styled('div')({
  margin: "40px 0px",
  display: "flex",
  flexWrap: "nowrap", 
  overflowX: "auto",
  gap: "3.312rem",
  padding: "0px 4px 0px 2.8rem",
  boxSizing: "border-box",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
});

const ScrollButton = styled("button")({
  borderRadius: "50%",
  height: "48px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "48px",
  background: "white",
  color: "black",
  fontWeight: "bold",
  cursor: "pointer",
  border: "none",
  "&:hover": {
    backgroundColor: "white",
  },
});

interface BrandListProps {
  shwoBrandComponent: boolean;
  categoryLable: string;
  ViewAllLink: string;
  brandsList: any;
  index:number;
  brandNameDesc?:string;
  navigateToDetailedProductView?:any
}

export function BrandList({
  brandsList,
  shwoBrandComponent,
  categoryLable,
  ViewAllLink,
  index,
  brandNameDesc,
  navigateToDetailedProductView
}: BrandListProps) {
  const [scrollStates, setScrollStates] = useState<{ [key: number]: { canScrollLeft: boolean, canScrollRight: boolean } }>({});
  const containerRefs = useRef<(HTMLDivElement | any)[]>([]); // Store refs for scrollable containers

  const checkScrollButtons = (index: number) => {
    const ref = containerRefs.current[index];
    if (ref) {
      const { scrollLeft, scrollWidth, clientWidth } = ref;

      // Check if the state really needs to be updated to avoid unnecessary renders
      const canScrollLeft = scrollLeft > 0;
      const canScrollRight = scrollLeft + clientWidth < scrollWidth;

      setScrollStates((prevStates) => {
        const prevState = prevStates[index] || {};
        if (prevState.canScrollLeft !== canScrollLeft || prevState.canScrollRight !== canScrollRight) {
          return {
            ...prevStates,
            [index]: {
              canScrollLeft,
              canScrollRight,
            },
          };
        }
        return prevStates; // Don't update state if nothing changed
      });
    }
  };

  const scrollRight = (index: number) => {
    const ref = containerRefs.current[index];
    if (ref) {
      const newScrollPosition = ref.scrollLeft + ref.clientWidth; // Scroll by one viewport width
      ref.scrollTo({
        left: Math.min(newScrollPosition, ref.scrollWidth),
        behavior: 'smooth',
      });
      setTimeout(() => checkScrollButtons(index), 300); // Check buttons after scrolling
    }
  };

  const scrollLeft = (index: number) => {
    const ref = containerRefs.current[index];
    if (ref) {
      const newScrollPosition = ref.scrollLeft - ref.clientWidth;
      ref.scrollTo({
        left: Math.max(newScrollPosition, 0),
        behavior: 'smooth',
      });
      setTimeout(() => checkScrollButtons(index), 100);
    }
  };

  useEffect(() => {
    brandsList?.forEach((_:any, index:number) => {
      if (containerRefs.current[index]) {
        checkScrollButtons(index);
      }
    });
  }, [brandsList]);

  return (
    <>
          <Container key={index}>
            <BrandHeader>
              <span style={{flex:1}}></span>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center",textAlign:"center",marginLeft:"44px",marginRight:"auto"  }}>
                <BrandName>{categoryLable}</BrandName>
                <p style={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "22px",
                  textAlign: "center",
                  color: "rgba(83, 80, 63, 1)",
                }}>
                 {brandNameDesc ?brandNameDesc: "Our latest vintage designer drop has hard to find, must-have pieces."}
                </p>
              </div>
              {brandsList  && (
                <div style={{
                  flex:1,
                  display: "flex",
                  gap: "20px",
                  justifyContent: "flex-end",
                  paddingRight: "30px",
                  alignItems: "center"
                }}>
                  <div
                    onClick={() => scrollLeft(index)} 
                    style={{
                      cursor: scrollStates[index]?.canScrollLeft ? 'pointer' : 'not-allowed',
                      opacity: scrollStates[index]?.canScrollLeft ? 1 : 0.5,
                    }}
                  >
                    <img style={{ transform: "scaleX(-1)"}} src={rightArrow} alt="arrow" width={46} height={12} />
                  </div>
                  <div
                    onClick={() => scrollRight(index)} 
                    style={{
                      cursor: scrollStates[index]?.canScrollRight ? 'pointer' : 'not-allowed',
                      opacity: scrollStates[index]?.canScrollRight ? 1 : 0.5,
                    }}
                  >
                    <img src={rightArrow} alt="arrow" width={46} height={12} />
                  </div>
                </div>
              )}
            </BrandHeader>

            <CategoriesContainer ref={el => {
              containerRefs.current[index] = el;
              if (el) {
                checkScrollButtons(index);
              }
            }}>
              {brandsList?.map((item: any, idx: number) => (
                <>
                  <SingleProductComponent navigateToDetailedProductView={navigateToDetailedProductView} product={item} />
                </>
              ))}
            </CategoriesContainer>
          </Container>
    </>
  );
}
